<template>
<div class="contact">
  <div
      class="contact-img"
      :style="bgImg"
  >
    <h2
        class="title support-center-title"
        data-aos="fade-up" 
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >Support Center</h2>
  </div>
  <div class="contact-block">
    <div class="contact-container">
      <h2 class="section-title">CONTACT</h2>
      <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M91.2742 36.0545C91.8246 36.0532 92.3698 36.1604 92.8786 36.3701C93.3875 36.5798 93.85 36.8878 94.2396 37.2765C94.6292 37.6652 94.9383 38.1269 95.1493 38.6352C95.3602 39.1436 95.4688 39.6885 95.4688 40.2389V88.9381C95.4674 89.4876 95.3579 90.0314 95.1464 90.5386C94.9348 91.0458 94.6255 91.5063 94.236 91.8939C93.8465 92.2815 93.3845 92.5886 92.8763 92.7977C92.3681 93.0067 91.8237 93.1136 91.2742 93.1123H52.0711L35.4555 104.101V93.1123H28.5695C28.02 93.1136 27.4756 93.0067 26.9675 92.7977C26.4593 92.5886 25.9973 92.2815 25.6078 91.8939C25.2183 91.5063 24.9089 91.0458 24.6974 90.5386C24.4859 90.0314 24.3763 89.4876 24.375 88.9381V40.2389C24.375 39.6885 24.4836 39.1436 24.6945 38.6352C24.9054 38.1269 25.2145 37.6652 25.6042 37.2765C25.9938 36.8878 26.4563 36.5798 26.9651 36.3701C27.474 36.1604 28.0192 36.0532 28.5695 36.0545H91.2742ZM91.2742 33.0076H28.5695C26.6524 33.013 24.8152 33.7762 23.4586 35.1309C22.102 36.4856 21.3362 38.3217 21.3281 40.2389V88.9381C21.3335 90.8605 22.1009 92.7023 23.4622 94.0598C24.8235 95.4172 26.6674 96.1795 28.5898 96.1795H32.4086V109.809L37.1617 106.661L52.9953 96.1795H91.2742C93.1931 96.1741 95.0319 95.4095 96.3887 94.0526C97.7456 92.6957 98.5103 90.857 98.5156 88.9381V40.2389C98.5129 38.3147 97.7467 36.4703 96.3851 35.1106C95.0236 33.751 93.1781 32.9873 91.2539 32.9873L91.2742 33.0076Z" fill="#3A3A3A"/>
        <path d="M33.5156 34.5312V28.3258C33.5089 27.6127 33.6429 26.9053 33.9099 26.2441C34.1769 25.5829 34.5717 24.9808 35.0717 24.4723C35.5717 23.9639 36.167 23.559 36.8237 23.2809C37.4803 23.0028 38.1853 22.8569 38.8984 22.8516H103.289C104.002 22.8569 104.707 23.0028 105.364 23.2809C106.02 23.559 106.616 23.9639 107.116 24.4723C107.616 24.9808 108.011 25.5829 108.278 26.2441C108.545 26.9053 108.679 27.6127 108.672 28.3258V73.2367C108.679 73.9498 108.545 74.6572 108.278 75.3184C108.011 75.9796 107.616 76.5817 107.116 77.0902C106.616 77.5986 106.02 78.0035 105.364 78.2816C104.707 78.5597 104.002 78.7056 103.289 78.7109H97.1953" stroke="#3A3A3A" stroke-width="3.03" stroke-miterlimit="10"/>
        <path d="M42.6562 56.875H77.1875" stroke="#3A3A3A" stroke-width="3.04" stroke-miterlimit="10"/>
        <path d="M42.6562 73.125H77.1875" stroke="#3A3A3A" stroke-width="3" stroke-miterlimit="10"/>
      </svg>
      <div class="contact-info">
        <a href="#" class="nav-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"/>
          </svg>
          Info@cemglobal.com
        </a>
        <a href="#" class="nav-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
                d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"/>
          </svg>
          (866) 915-1212
        </a>
      </div>
      <p class="text-item">
        If you have any questions or concerns, please don’t hesitate to contact us. Our amazing customer support will do
        their absolute best to ensure your problems are resolved as fast as possible. We are available to contact at any
        point 24/7.
      </p>
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
export default {
  name: "Contact",
  components: {Footer},
  data(){
    return{
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/support-bg.png')})`
      }
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.contact{
  background-color: $white;
  .contact-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
  .contact-block{
    padding: 42px 0 136px;
    .contact-container{
      text-align: center;
      .contact-info{
        width: fit-content;
        margin: 0 auto;
        a{
          display: flex;
          color: $homeTextBlack;
          text-align: start;
          margin-top: 8px;
          &:nth-child(1){
            margin-top: 0;
          }
          svg{
            width: 15px;
            fill: $homeTextBlack;
            margin-right: 7px;
          }
        }
      }
      .text-item{
        margin-top: 20px;
      }
    }
  }
}

</style>